import { defineStore } from 'pinia'
import { deleteFilter,
    createFilter,
    updateFilter,
    getFilters,
    activateFilter } from '@/api/requests/filter/filterRequests'

import { GetFiltersResponseData } from '@/types/api/filter/filterReponses'
import { getLabels } from '@/api/requests/field/selectFieldRequests'
import { CreateFilterRequestData,
    ExtendedFilter, FilterFieldField,
    UpdateFilterRequestData } from '@/types/api/filter/filterTypes'
import { FieldLabel } from '@/types/api/field/fieldTypes'
import { GetLabelsResponse } from '@/types/api/field/fieldResponses'
import { useAppStore } from '@/store/app'

interface State {
    filters: GetFiltersResponseData | null;
    activeFilter: ExtendedFilter | null;
    // Can't seem to find a way to type this properly
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    relatedLabels: any | null;
    selectedIds: Array<number>;
    selectedAll: boolean;
    openFilterManagement: boolean;
    search: {[key: string]: string};
}
export const useFilterStore = defineStore('filters', {
    state: (): State => {
        return {
            filters: null,
            activeFilter: null,
            relatedLabels: {
            },
            selectedIds: [],
            selectedAll: false,
            openFilterManagement: false,
            search: {
            },
        }
    },
    getters: {
        getFilters: state => state.filters,
        getActiveFilter: state => state.activeFilter,
        getActiveFilterId: state => state.activeFilter?.id,
        getPublicFilters: state => state.filters?.public_filters,
        getPrivateFilters: state => state.filters?.private_filters,
        getRelatedFieldLabel: state => (moduleId: number, fieldKey: string) => {
            return state.relatedLabels?.[moduleId]?.[fieldKey]
        },
    },
    actions: {
        async loadFilters(moduleId?: number) {
            if(moduleId) {
                this.filters = await getFilters(moduleId)
            }

            return this.filters
        },
        async setActiveFilter(filter: ExtendedFilter) {
            if(filter.id !== this.activeFilter?.id) {
                this.activeFilter = filter

                return await activateFilter(filter.module_id, filter.id)
            }
        },
        getRelatedLabels(moduleId: number, field: FilterFieldField, ids: Array<string>) {
            const requestFields: Array<string> = []

            // eslint-disable-next-line no-prototype-builtins
            if(! this.relatedLabels.hasOwnProperty(moduleId)) {
                this.relatedLabels[moduleId] = {
                }
            }

            // eslint-disable-next-line no-prototype-builtins
            if(! this.relatedLabels[moduleId].hasOwnProperty(field.key)) {
                this.relatedLabels[moduleId][field.key] = []
            }

            ids.forEach((id) => {
                // eslint-disable-next-line no-prototype-builtins
                requestFields.push(id)
            })

            if(requestFields.length) {
                const labels = getLabels(field?.id, requestFields)

                labels.then((result: GetLabelsResponse) => {
                    result.data.data.forEach((data: FieldLabel) => {
                        this.relatedLabels[moduleId][field.key].push(data)
                    })
                })
            }

            return this.relatedLabels
        },
        async createFilter(data: CreateFilterRequestData) {
            return await createFilter(data)
        },
        async updateFilter(filterId: number, data: UpdateFilterRequestData) {
            return await updateFilter(filterId, data)
        },
        async deleteFilter(filterId: number|null, callback?: () => void) {
            useAppStore().verificationActionCallback = callback

            return await deleteFilter(filterId)
        },
        async setSelectedIds(selectedIds: Array<number>) {
            this.selectedIds = selectedIds
        },
    },
})
