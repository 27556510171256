<template>
  <!-- Narrow sidebar -->
  <div class='z-30 hidden w-28 overflow-y-auto bg-gray-700 md:block'>
    <div class='flex w-full flex-col items-center pt-6'>
      <div class='flex shrink-0 items-center'>
        <img
          class='h-8 w-auto'
          src='https://tailwindui.com/img/logos/workflow-mark.svg?color=white'
          alt='Workflow'
        >
      </div>
      <div class='mt-6 w-full flex-1 space-y-1 px-2'>
        <div
          v-for='groups in sidebarNavigation'
          :key='groups'
        >
          <div v-if='!groups.icon'>
            <div
              class='text-white'
            >
              <div
                v-for='group in groups'
                :key='group'
                :class='[ group && "border-b border-gray-400 text-white py-0.5"]'
                @click='showLess'
              >
                <RouterLink
                  v-for='item in group'
                  :key='item.name'
                  v-slot='{ route, navigate, href }'
                  :to='"/" + item.name'
                  custom
                >
                  <div
                    class='group relative my-1 flex w-full flex-col items-center justify-center rounded-md text-xs font-medium hover:bg-gray-500'
                  >
                    <a
                      :class='[isActiveRoute(route) ? "bg-gray-800 text-white" : "text-gray-100 hover:text-white"]'
                      class='relative z-10 flex w-full cursor-pointer flex-col items-center justify-center rounded-md p-3'
                      :aria-current='isActiveRoute(route) ? "page" : undefined'
                      @click.exact='navigate'
                      @click.meta.exact='openInNewTab(href)'
                      @click.ctrl.exact='openInNewTab(href)'
                      @mouseleave='hideFilterMenu'
                    >
                      <component
                        :is='
                          item.icon'
                        :class='[isActiveRoute(route) ? "text-white" : "text-gray-300 group-hover:text-white", "h-6 w-6"]'
                        aria-hidden='true'
                      />
                      <span class='mt-2 text-center'>{{ item.label }}</span>
                    </a>
                    <div
                      v-if='item.public_filters'
                      class='absolute right-0 z-20 cursor-pointer text-white opacity-0 transition-all group-hover:opacity-100 group-hover:duration-500'
                      @click='showFilterMenu(item)'
                    >
                      <ChevronRightIcon
                        class='h-5 w-5'
                        aria-hidden='true'
                      />
                    </div>
                  </div>
                </RouterLink>
              </div>
            </div>
          </div>

          <div
            v-else
            class='border-gray-400 pb-1'
          >
            <div
              class='group relative flex cursor-pointer flex-col items-center  justify-center rounded-md p-3 text-xs font-medium text-gray-100 hover:bg-gray-500 hover:text-white'
              @click='showMore(groups)'
            >
              <component
                :is='
                  groups.icon'
                aria-hidden='true'
                class='h-6 w-6 text-gray-300 group-hover:text-white'
              />
              <span class='mt-2 text-center group-hover:text-white'>{{ groups.label }}</span>
              <div
                class='absolute right-0 z-20 cursor-pointer'
              >
                <DotsVerticalIcon
                  class='h-5 w-5'
                  aria-hidden='true'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
  import { defineComponent } from 'vue'
  import { useRoute, RouterLink, RouteLocation } from 'vue-router'
  import { IconComponents } from '@/compositions/useIconComponents'
  import { ChevronRightIcon } from '@heroicons/vue/solid'

  export default defineComponent({
    name: 'NarrowSidebar',
    components: {
      RouterLink,
      ChevronRightIcon,
      ...IconComponents,
    },
    props: {
      sidebarNavigation: {
        type: Object,
        default: null,
      },
    },
    emits: ['show-filter-menu', 'hide-filter-menu', 'show-more', 'show-less'],
    setup(props, { emit }) {
      const route = useRoute()

      const isActiveRoute = (menuRoute: RouteLocation) => {
        return route.meta.module === menuRoute.params.module
      }

      const showFilterMenu = (module: object) => emit('show-filter-menu', module)
      const hideFilterMenu = () => emit('hide-filter-menu')

      const showMore = (items: object) => emit('show-more', items)
      const showLess = () => emit('show-less')

      const openInNewTab = (href: string) => {
        window.open(href, '_blank')
      }

      return {
        isActiveRoute,
        showFilterMenu,
        hideFilterMenu,
        showMore,
        showLess,
        openInNewTab,
      }
    },
  })
</script>
