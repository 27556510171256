<template>
  <div class='relative flex h-full'>

    <FlashMessage class='z-50'/>

    <div
      ref='navigationRef'
      class='relative flex w-max flex-row overflow-visible'
    >
      <NarrowSidebar
        :sidebar-navigation='sidebarNavigation'
        @show-filter-menu='onShowFilterMenu($event)'
        @hide-filter-menu='onHideFilterMenu'
        @show-more='onShowMore($event)'
        @show-less='onShowLess'
      />
      <ExtendedNarrowSidebar
        :items='extendedSidebarNavigation'
        @show-filter-menu='onShowFilterMenu($event)'
        @hide-filter-menu='onHideFilterMenu'
        @hide-extended-sidebar='onShowLess'
      />
      <MobileMenu
        :sidebar-navigation='sidebarNavigation'
        :mobile-menu-open='mobileMenuOpen'
        @close-menu='mobileMenuOpen = false'
      />
      <div
        class='relative'
        :class='[ extendedSidebarNavigation.length > 0 ? "left-full" : "left-0" ]'
      >
        <FilterMenu
          :open='showFilters'
          :current-module='currentModule'
          @mouse-left='onHideFilterMenu'
        />
      </div>
    </div>

    <!-- Content area -->
    <div class='flex flex-1 flex-col overflow-hidden'>
      <header class='w-full'>
        <div class='relative flex shrink-0 border-b border-gray-200 bg-white py-2 shadow-sm'>
          <button
            type='button'
            class='border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500 md:hidden'
            @click='mobileMenuOpen = true'
          >
            <span class='sr-only'>Open sidebar</span>
            <MenuAlt2Icon
              class='h-6 w-6'
              aria-hidden='true'
            />
          </button>
          <div class='flex flex-1 px-4 sm:px-6'>
            <GlobalSearch/>
            <div class='ml-2 flex items-center space-x-4 sm:ml-6 sm:space-x-6'>
              <!-- Profile dropdown -->
              <!--              <Menu-->
              <!--                as='div'-->
              <!--                class='relative shrink-0'-->
              <!--              >-->
              <!--                <div>-->
              <!--                  <MenuButton class='flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2'>-->
              <!--                    <span class='sr-only'>Open notifications</span>-->
              <!--                    <BellIcon-->
              <!--                      class='h-6 w-6'-->
              <!--                      aria-hidden='true'-->
              <!--                    />-->
              <!--                  </MenuButton>-->
              <!--                </div>-->
              <!--                <transition-->
              <!--                  enter-active-class='transition ease-out duration-100'-->
              <!--                  enter-from-class='transform opacity-0 scale-95'-->
              <!--                  enter-to-class='transform opacity-100 scale-100'-->
              <!--                  leave-active-class='transition ease-in duration-75'-->
              <!--                  leave-from-class='transform opacity-100 scale-100'-->
              <!--                  leave-to-class='transform opacity-0 scale-95'-->
              <!--                >-->
              <!--                  <MenuItems class='absolute right-0 mt-2 w-[400px] origin-top-right rounded-md bg-white p-4 py-1 shadow-lg ring-1 ring-black/5 focus:outline-none'>-->
              <!--                    <div>-->
              <!--                      <ul-->
              <!--                        role='list'-->
              <!--                        class='divide-y divide-gray-200'-->
              <!--                      >-->
              <!--                        <MenuItem-->
              <!--                          v-for='item in userNotifications'-->
              <!--                          :key='item.date'-->
              <!--                        >-->
              <!--                          <a :href='item.action'>-->
              <!--                            <li class='py-4'>-->
              <!--                              <div class='flex space-x-3'>-->
              <!--                                <img-->
              <!--                                  class='h-6 w-6 rounded-full'-->
              <!--                                  :src='item.user.imageUrl'-->
              <!--                                  alt=''-->
              <!--                                >-->
              <!--                                <div class='flex-1 space-y-1'>-->
              <!--                                  <div class='flex items-center justify-between'>-->
              <!--                                    <h3 class='text-sm font-medium'>{{ item.user.name }}</h3>-->
              <!--                                    <p class='text-sm text-gray-500'>{{ item.date }}</p>-->
              <!--                                  </div>-->
              <!--                                  <p class='text-sm text-gray-500'>{{ item.title }}</p>-->
              <!--                                </div>-->
              <!--                              </div>-->
              <!--                            </li>-->
              <!--                          </a>-->
              <!--                        </MenuItem>-->
              <!--                      </ul>-->
              <!--                    </div>-->
              <!--                  </MenuItems>-->
              <!--                </transition>-->
              <!--              </Menu>-->
              <!--              <button-->
              <!--                  type='button'-->
              <!--                  class='flex items-center justify-center rounded-full p-0 text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2'-->
              <!--              >-->
              <!--                <AcademicCapIcon-->
              <!--                    class='h-6 w-6'-->
              <!--                    aria-hidden='true'-->
              <!--                />-->
              <!--                <span class='sr-only'>Learn</span>-->
              <!--              </button>-->

              <Menu
                as='div'
                class='relative shrink-0'
              >
                <div>
                  <MenuButton class='flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2'>
                    <span class='sr-only'>Open user menu</span>
                    <img
                      v-if='profileImage'
                      :src='profileImage'
                      alt='profile image'
                    >
                    <div
                      v-else-if='userInitials'
                      class='flex h-9 w-9 items-center justify-center rounded-full bg-gray-500 text-sm text-white'
                    >{{ userInitials }}</div>
                  </MenuButton>
                </div>
                <transition
                  enter-active-class='transition ease-out duration-100'
                  enter-from-class='transform opacity-0 scale-95'
                  enter-to-class='transform opacity-100 scale-100'
                  leave-active-class='transition ease-in duration-75'
                  leave-from-class='transform opacity-100 scale-100'
                  leave-to-class='transform opacity-0 scale-95'
                >
                  <MenuItems class='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 focus:outline-none'>
                    <MenuItem
                      v-for='item in userNavigation'
                      :key='item.name'
                      v-slot='{ active }'
                    >
                      <a
                        v-if='item.action'
                        :class='[active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700 cursor-pointer"]'
                        @click='item.action'
                      >{{ item.name }}</a>
                      <a
                        v-else
                        :href='item.href'
                        :class='[active ? "bg-gray-100" : "", "block px-4 py-2 text-sm text-gray-700"]'
                      >{{ item.name }}</a>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>
      </header>

      <!-- Main content -->
      <div class='flex flex-1 items-stretch overflow-hidden'>
        <main class='relative flex-1 overflow-y-auto'>
          <AppLoader/>

          <VerificationModal class='z-50'/>

          <!-- Primary column -->
          <section
            aria-labelledby='primary-heading'
            class='flex h-full min-w-0 flex-1 flex-col overflow-x-hidden lg:order-last'
          >
            <router-view :key='$router.name'/>
          </section>
        </main>

        <component
          :is='ticketsBar'
          v-if='ticketsBar'
        />

      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, defineAsyncComponent, ref, onMounted, computed } from 'vue'
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
  import { MenuAlt2Icon,
           AcademicCapIcon,
           BellIcon } from '@heroicons/vue/outline'
  import NarrowSidebar from './partials/default/NarrowSidebar.vue'
  import ExtendedNarrowSidebar from './partials/default/ExtendedNarrowSidebar.vue'
  import MobileMenu from './partials/default/mobile-menu.vue'
  import { useAuthStore } from '@/store/authentication'
  import GlobalSearch from '@/layouts/partials/default/GlobalSearch.vue'
  import FilterMenu from '@/layouts/partials/default/FilterMenu.vue'
  import { useMenuStore } from '@/store/menu'
  import { useRouter } from 'vue-router'
  import FlashMessage from '@/components/FlashMessage.vue'
  import { AsideMenuItem } from '@/types/api/menu/menuTypes'
  import AppLoader from '@/components/AppLoader.vue'
  import VerificationModal from '@/components/actions/CustomActions/Global/VerificationModal.vue'
  import { onClickOutside } from '@vueuse/core'
  import { useUserStore } from '@/store/user'
  import { getUserInfo } from '@/api/requests/user/login'
  import { logoutUser } from '@/api/requests/user/logout'

  const SHOW_TICKETS_BAR = import.meta.env.VITE_SHOW_TICKETS_BAR === 'true'

  export default defineComponent({
    // eslint-disable-next-line vue/match-component-file-name
    name: 'DefaultLayout',
    components: {
      FlashMessage,
      FilterMenu,
      GlobalSearch,
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      MenuAlt2Icon,
      AcademicCapIcon,
      BellIcon,
      NarrowSidebar,
      ExtendedNarrowSidebar,
      MobileMenu,
      AppLoader,
      VerificationModal,
    },
    setup() {
      const authStore = useAuthStore()
      const menuStore = useMenuStore()
      const userStore = useUserStore()
      const showFilters = ref(false)
      const sidebarNavigation = ref<Array<AsideMenuItem>>([])
      const extendedSidebarNavigation = ref({

      })
      const router = useRouter()
      const navigationRef = ref(null)

      onClickOutside(navigationRef, () => {
        onShowLess()
      })

      const logout = () => {
        logoutUser()
        authStore.logout()
      }

      const navToProfile = () => {
        router.push({
          name: 'profile',
        })
      }

      onMounted(() => {

        router.isReady().then(() => {
          if(localStorage.getItem('access_token')) {
            menuStore.loadMenu().then(() => {
              if(menuStore) {

                for (const [, value] of Object.entries(menuStore.menu ?? {
                })) {
                  sidebarNavigation.value.push(value)
                }
              }
            })
          }
        })
      })

      const profileImage = computed(() => {
        return userStore?.user?.profile_image || false
      })

      const userInitials = computed(() => {
        return userStore?.user?.first_name.charAt(0) + userStore?.user?.last_name.charAt(0)
      })

      onMounted(() => {
        if (!userStore.user) getUserInfo()
      })

      const userNavigation = [
        {
          name: 'Profile', action: navToProfile,
        },
        {
          name: 'Sign out', action: logout,
        },
      ]

      const userNotifications = [
        {
          user: {
            name: 'Andrej Hýll',
            imageUrl: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80',
          },
          title: 'Notification',
          body: 'Message',
          action: '#',
          date: '1h ago',
        },
        {
          user: {
            name: 'Andrej Hýll',
            imageUrl: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80',
          },
          title: 'Notification',
          body: 'Message',
          action: '#',
          date: '2h ago',
        },
        {
          user: {
            name: 'Andrej Hýll',
            imageUrl: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80',
          },
          title: 'Notification',
          body: 'Message',
          action: '#',
          date: '3h ago',
        },
        {
          user: {
            name: 'Andrej Hýll',
            imageUrl: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80',
          },
          title: 'Notification',
          body: 'Message',
          action: '#',
          date: '4h ago',
        },
        {
          user: {
            name: 'Andrej Hýll',
            imageUrl: 'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80',
          },
          title: 'Notification',
          body: 'Message',
          action: '#',
          date: '5h ago',
        },
      ]

      const mobileMenuOpen = ref(false)

      const currentModule = ref(Object())

      const onShowFilterMenu = (module: object) => {
        currentModule.value = module
        showFilters.value = true
      }

      const onHideFilterMenu = () => {
        showFilters.value = false
      }
      let ticketsBar = null

      const onShowMore = (items: object) => {
        if (JSON.stringify(extendedSidebarNavigation.value) === JSON.stringify(Object.values(items).filter(item => typeof(item) === 'object'))) {
          onShowLess()
        } else {
          extendedSidebarNavigation.value = Object.values(items).filter(item => typeof(item) === 'object')
        }
      }

      const onShowLess = () => {
        extendedSidebarNavigation.value = []
      }

      if (SHOW_TICKETS_BAR) {
        ticketsBar = defineAsyncComponent({
          loader: () => import('@/layouts/partials/default/TicketsBar.vue'),
        })
      }

      return {
        sidebarNavigation,
        userNavigation,
        userNotifications,
        mobileMenuOpen,
        showFilters,
        logout,
        ticketsBar,
        onShowFilterMenu,
        onHideFilterMenu,
        onShowMore,
        onShowLess,
        currentModule,
        extendedSidebarNavigation,
        showTickets: SHOW_TICKETS_BAR,
        navigationRef,
        profileImage,
        userInitials,
        navToProfile,
      }
    },
  })

</script>
