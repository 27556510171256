import { defineStore } from 'pinia'
import { describeModule,
    getRelatedIndex,
    getRelatedDetail,
    getRelatedModules,
    getSessionsList,
    endSession,
    getAvailableModulesForRole,
    getModuleFieldsForRole,
    getModuleActionsForRole, getClientPermissions, getModuleSelectFieldsForRole } from '@/api/requests/module/moduleRequests'

import { useToString } from '@vueuse/core'
import { useAppStore } from '@/store/app'
import { SimplePermission } from '@/types/general/PermissionType'
import { Module, SlimModule } from '@/types/general/ModuleType'
import { Tab } from '@/types/general/TabType'
import { NestedObject } from '@/types/general/utilTypes'
import { ExtendedFilter } from '@/types/api/filter/filterTypes'
import { PaginatedData } from '@/types/general/PaginatedData'
import { Action, ActionStatus } from '@/types/general/ActionType'
import { MetaField } from '@/types/api/filter/metafieldTypes'
import { AxiosRequestConfig } from 'axios'

/* eslint-disable camelcase */
interface State {
    module: Module | null;
    activeTab: Tab | null;
    indexData: {
        active_filter: ExtendedFilter;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        results: PaginatedData<any>;
    } | null;
    detailData: NestedObject | null;
    detailIndexData: {
        active_filter: ExtendedFilter;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        results: PaginatedData<any>;
    } | null;
    refresh: boolean;
    disableRefetch: boolean;
    editEnabled: boolean;
    isSending: boolean;
    related_modules: Array<Module>;
    modules_for_role: Array<SlimModule>;
    module_fields_for_role: Array<MetaField & {can_read: boolean, can_write: boolean}>;
    module_select_fields_for_role: Array<{has_access: boolean, key: string, name: string, label: string}>;
    module_actions_for_role: Array<Action>;
    client_permissions: Array<SimplePermission>;
    actionStatus: ActionStatus|null;
}
/* eslint-enable camelcase */
export const useModuleStore = defineStore('module', {
    state: (): State => {
        return {
            module: null,
            activeTab: null,
            indexData: null,
            detailData: null,
            detailIndexData: null,
            refresh: false,
            disableRefetch: false,
            editEnabled: false,
            isSending: false,
            related_modules: [],
            modules_for_role: [],
            module_fields_for_role: [],
            module_select_fields_for_role: [],
            module_actions_for_role: [],
            client_permissions: [],
            actionStatus: {
                name: '',
                status: '',
            },
        }
    },
    getters: {
        getModule: state => state.module as Module,
        getCurrentModuleId: state => state.module?.id,
        getCurrentModuleLabel: state => state.module?.label,
        getCurrentModuleName: state => state.module?.name,
        getRefresh: state => state.refresh,
        getDisableRefetch: state => state.disableRefetch,
        getIsSending: state => state.isSending,
        getActiveTab: state => state.activeTab,
        getIndexData: state => state.indexData,
        getDetailData: state => state.detailData,
        getDetailIndexData: state => state.detailIndexData,
        getCurrentDetailId: state => state.detailData?.id as number|string|undefined,
        getRelatedModulesData: state => state.related_modules,
        getModulesForRoleData: state => state.modules_for_role,
        getModuleFieldsForRoleData: state => state.module_fields_for_role,
        getModuleSelectFieldsForRoleData: state => state.module_select_fields_for_role,
        getModuleActionsForRoleData: state => state.module_actions_for_role,
    },
    actions: {
        async describeModule(module: string|number, saveToStore = true) {
            const appStore = useAppStore()

            appStore.abortController = new AbortController()
            if (saveToStore) {
                this.module = await describeModule(module)
            } else {
                return await describeModule(module)
            }
        },
        async getRelatedIndex(module: string|null, filterId?: number|null, queryParams?: NestedObject) {
            const resolved = useToString(module) ? module : this.module?.name

            if(typeof resolved === 'undefined') {
                throw new Error('Module is not defined')
            }
            this.indexData = await getRelatedIndex(resolved, filterId, queryParams)

            return this.indexData
        },
        async getRelatedDetail(module: string|null, id: number, saveToStore = true) {
            const resolved = useToString(module) ? module : this.module?.name

            if(typeof resolved === 'undefined') {
                throw new Error('Module is not defined')
            }

            if (saveToStore) {
                this.detailData = await getRelatedDetail(resolved, id)
            } else {
                return await getRelatedDetail(resolved, id)
            }
        },
        async getSessionsList(module: string, id: number) {
            return getSessionsList(module, id)
        },
        async endSession(module: string, id: number, sessionId: string) {
            return endSession(module, id, sessionId)
        },
        async getDetailList(module: string|null, filterId:number|null, queryParams?: AxiosRequestConfig) {
            const resolved = useToString(module) ? module : this.module?.name

            if(typeof resolved === 'undefined') {
                throw new Error('Module is not defined')
            }

            this.detailIndexData = await getRelatedIndex(resolved, filterId, queryParams)
        },
        async getRelatedModules(module?: string|null, withSelf = false) {
            const resolved = useToString(module) ? module : this.module?.name

            if(typeof resolved === 'undefined') {
                throw new Error('Module name is defined')
            }

            this.related_modules = await getRelatedModules(resolved, withSelf)
        },
        async getAvailableModulesForRole(module: string, roleId?: number|null) {
            this.modules_for_role = await getAvailableModulesForRole(module, roleId)
        },
        async getModuleFieldsForRole(module: string, moduleId: number, roleId?: number|null) {
            this.module_fields_for_role = await getModuleFieldsForRole(module, moduleId, roleId)
        },
        async getModuleSelectFieldsForRole(module: string, moduleId: number, roleId?: number|null) {
            this.module_select_fields_for_role = await getModuleSelectFieldsForRole(module, moduleId, roleId)
        },
        async getModuleActionsForRole(module: string, moduleId: number, roleId?: number|null) {
            this.module_actions_for_role = await getModuleActionsForRole(module, moduleId, roleId)
        },
        async getClientPermissions(id: number) {
            this.client_permissions = await getClientPermissions(id)
        },
    },
})
