import InputError from '@/components/inputs/base/InputError.vue'
import { SearchCircleIcon, SearchIcon } from '@heroicons/vue/outline'
import { getCurrentInstance, PropType } from 'vue'
import { SelectOption, PartialField } from '@/types/general/FieldType'
import { NestedObject } from '@/types/general/utilTypes'

export const InputFieldProps = {
    modelValue: {
        type: [String, Array as PropType<SelectOption[]>, Object as PropType<NestedObject>, Number, Boolean],
        default: null,
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
        default: null,
    },
    placeholder: {
        type: String,
        default: null,
    },
    ariaDescribedBy: {
        type: String,
        default: null,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    error: {
        type: Object as PropType<NestedObject | string | null>,
        default: null,
    },
    editMode: {
        type: Boolean,
        default: false,
    },
    inputClasses: {
        type: String,
        default: '',
    },
    previewClasses: {
        type: String,
        default: '',
    },
    field: {
        type: Object as PropType<PartialField>,
        required: true,
        default: {

        },
    },
    isFilterField: {
        type: Boolean,
        default: false,
    },
    record: {
        type: Object,
        default: {

        },
    },
    indexLabel: {
        type: [Object, Number, String, Boolean],
        default: null,
    },
    values: {
        type: Array as PropType<SelectOption[]>,
        default: [],
    },
    view: {
        type: String,
        default: 'detail',
    },
}

export const SelectFieldProps = {
    selectedOptions: {
        type: Array as PropType<SelectOption[]>,
        default: [],
    },
    isLoadingOptions: {
        type: Boolean,
        default: false,
    },
}

export const InputFieldComponents = {
    SearchCircleIcon,
    SearchIcon,
    InputError,
}

export const InputFieldEmits = [
    'update:modelValue',
]

export default function() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { emit, props } = getCurrentInstance() as any

    function updateValue(value: null|string|NestedObject|number|boolean|Array<string|number>) {
        emit('update:modelValue', value)
    }

    const getLabel = () => {
        if (props.field.mandatory) {
            return '<span class="text-red-600" data-cy="mandatory-field">* </span>' + (props.label ? (props.label + ':') : '')
        } else {
            return props.label ? props.label + ':' : ''
        }
    }

    const clearValue = () => {
        emit('update:modelValue', '')
    }

    return {
        updateValue,
        clearValue,
        getLabel,
    }
}
