<template>
  <!-- Mobile menu -->
  <TransitionRoot
    as='template'
    :show='opened'
  >
    <Dialog
      as='div'
      class='md:hidden'
      @close='$emit("close-menu")'
    >
      <div class='fixed inset-0 z-40 flex'>
        <TransitionChild
          as='template'
          enter='transition-opacity ease-linear duration-300'
          enter-from='opacity-0'
          enter-to='opacity-100'
          leave='transition-opacity ease-linear duration-300'
          leave-from='opacity-100'
          leave-to='opacity-0'
        >
          <DialogOverlay class='fixed inset-0 bg-gray-600/75' />
        </TransitionChild>
        <TransitionChild
          as='template'
          enter='transition ease-in-out duration-300 transform'
          enter-from='-translate-x-full'
          enter-to='translate-x-0'
          leave='transition ease-in-out duration-300 transform'
          leave-from='translate-x-0'
          leave-to='-translate-x-full'
        >
          <div class='relative flex w-full max-w-xs flex-1 flex-col bg-gray-700 pt-5 pb-4'>
            <TransitionChild
              as='template'
              enter='ease-in-out duration-300'
              enter-from='opacity-0'
              enter-to='opacity-100'
              leave='ease-in-out duration-300'
              leave-from='opacity-100'
              leave-to='opacity-0'
            >
              <div class='absolute top-1 right-0 -mr-14 p-1'>
                <button
                  type='button'
                  class='flex h-12 w-12 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white'
                  @click='$emit("close-menu")'
                >
                  <XIcon
                    class='h-6 w-6 text-white'
                    aria-hidden='true'
                  />
                  <span class='sr-only'>Close sidebar</span>
                </button>
              </div>
            </TransitionChild>
            <div class='flex shrink-0 items-center px-4'>
              <img
                class='h-8 w-auto'
                src='https://tailwindui.com/img/logos/workflow-mark.svg?color=white'
                alt='Workflow'
              >
            </div>
            <div class='mt-5 h-0 flex-1 overflow-y-auto px-2'>
              <nav class='flex h-full flex-col'>
                <div class='space-y-1'>
                  <a
                    v-for='item in sidebarNavigation'
                    :key='item.name'
                    :href='item.href'
                    :class='[item.current ? "bg-gray-800 text-white" : "text-gray-100 hover:bg-gray-800 hover:text-white", "group py-2 px-3 rounded-md flex items-center text-sm font-medium"]'
                    :aria-current='item.current ? "page" : undefined'
                  >
                    <component
                      :is='item.icon'
                      :class='[item.current ? "text-white" : "text-gray-300 group-hover:text-white", "mr-3 h-6 w-6"]'
                      aria-hidden='true'
                    />
                    <span>{{ item.name }}</span>
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div
          class='w-14 shrink-0'
          aria-hidden='true'
        >
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
  import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
  import { XIcon } from '@heroicons/vue/outline'
  import { toRefs, withDefaults } from 'vue'
  import { AsideMenuItem } from '@/types/api/menu/menuTypes'

  interface Props {
    sidebarNavigation: Array<AsideMenuItem>|null;
    mobileMenuOpen: boolean;
  }

  const props = withDefaults(defineProps<Props>(), {
    sidebarNavigation: null,
    mobileMenuOpen: false,
  })

  defineEmits(['close-menu'])

  const opened = toRefs(props).mobileMenuOpen

</script>
